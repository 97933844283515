<template>
    <header v-if="isShow">
        <nav class="navbar navbar-expand-lg ml-auto">
            <div class="container-fluid">
                <a class="navbar-brand" href="/cases">QR</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon" style="color: white;"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div class="navbar-nav ml-auto">
                        <a v-for="item in editMenu" :key="item.name" :href="item.url" @click.prevent="handleMenuClick(item)"
                            class="nav-link">{{ item.name }}</a>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>


<script>
export default {
    data() {
        return {
            menu: [
                { name: 'Список дел', url: '/cases' },
                { name: 'Список вещ доков', url: '/evids' },
                { name: 'Добавить дело', url: '/case/add' },
                { name: 'Добавить Вещ. док', url: '/evid/add' },
                { name: 'Выйти', url: `/logout` },
            ],
        };
    },

    // beforeRouteEnter(to, from, next) {
    //     const isTokenExist = document.cookie.split(';').some((item) => item.trim().startsWith('token='));
    //     next(vm => {
    //         vm.isShow = isTokenExist;
    //     });
    // },

    computed: {
        editMenu() {
            return localStorage.getItem('role') == 'user' ? this.menu.filter((item, index) => index != 2 && index != 3) : this.menu;
        },

        isShow() {
            return this.$route.path != '/login';
        },
    },

    methods: {
        handleMenuClick(item) {
            if (item.url === '/logout') {
                this.logout(localStorage.getItem('id'));
            } else {
                this.$router.push(item.url);
            }
        },

        logout(userId) {
            const logoutUrl = `/logout/${userId}`;

            this.$axios.post(logoutUrl)
                .then(() => {
                    this.$cookies.remove('token');

                    localStorage.removeItem("id");
                    localStorage.removeItem("role");

                    setTimeout(() => {
                        this.$router.push('/login');
                    }, 500);

                })
                .catch(error => {
                    console.error('Logout failed', error);
                    // Обработка ошибки авторизации
                });
        },

        getUserInfo(user_id) {
            this.$axios.get('/user', {
                params: {
                    user_id: user_id
                }
            })
        },

        isRole() {
            const role = localStorage.getItem('role');

            if (role === 'admin' || role === 'control')
                return true;
            return false;
        },
    }
};
</script>

<style scoped>
header {
    margin: 50px 0;
    padding: 30px;

    border-radius: 30px;
    background: #7393B3;

    font-weight: bold;
}

.navbar-toggler {
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* body {
        background: #F0FFFF;
    } */

header a {
    color: #fff;
}

@media screen and (min-width: 992px) {
    .ml-auto {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}
</style>