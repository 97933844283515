<template>
    <main class="form-signin text-center">
        <form @submit.prevent="login">
            <h3 class="text-center">Добавить вещ док в систему</h3>
            <br>
            <div class="form-floating my-4">
                <label for="investigationId">Дело:</label>
                <select id="investigationId" v-model="formData.investigation_id" class="form-select" required>
                    <option v-for="investigation in filteredInvestigations" :key="investigation.id" :value="investigation.id">{{ investigation.name }}</option>
                </select>
            </div>

            <div class="form-floating my-4">
                <input type="text" class="form-control" id="name" placeholder="Название:" v-model="formData.name" required>
            </div>

            <div class="form-floating my-4">
                <input type="text" class="form-control" id="address" placeholder="Адрес:" v-model="formData.address" required>
            </div>

            <div class="form-floating my-4">
                <input type="text" class="form-control" id="location" placeholder="Место расположения:" v-model="formData.location" required>
            </div>

            <!-- Добавьте остальные поля здесь -->
            <div class="form-floating my-4">
                <input type="text" class="form-control" id="info" placeholder="Краткая информация о преступлении (ст. УК РФ):" v-model="formData.info" required>
            </div>

            <div class="form-floating my-4">
                <input type="text" class="form-control" id="specialist_fio" placeholder="ФИО специалиста, производившего изъятие:" v-model="formData.specialist_fio" required>
            </div>

            <div class="form-floating my-4">
                <input type="text" class="form-control" id="investigator_fio" placeholder="ФИО следователя (дознавателя):" v-model="formData.investigator_fio" required>
            </div>

            <div class="form-floating my-4">
                <input type="text" class="form-control" id="evid_info" placeholder="Информация об изъятых вещественных доказательствах:" v-model="formData.evid_info" required>
            </div>

            <div class="form-floating my-4">
                <input type="file" class="form-control" id="photo_video" placeholder="Фото/Видео:" multiple @change="handleFileChange">
            </div>
            <br>
            <!-- <div class="form-floating my-4">
                <label for="userId">Пользователь:</label>
                <select id="userId" v-model="formData.user_id" class="form-select" required>
                    <option v-for="user in investigationInfo" :key="user.id" :value="user.id">{{ user.fio }} ({{ user.role }})
                    </option>
                </select>
            </div> -->
            <button @click="addEvid" type="submit" class="btn btn-primary w-100">Создать вещ док</button>
        </form>
    </main>
</template>

<script>

export default {
    data() {
        return {
            investigations: [],
            formData: {
                investigation_id: 0,
                name: '',
                address: '',
                location: '',
                info: '',
                specialist_fio: '',
                investigator_fio: '',
                evid_info: '',
                photo_video: '',
                user_id: 0,
                status: 1,

            },
            showAlertError: false,
            showAlertSuccess: false,
            investigationInfo: [] // Данные о пользователях для выпадающего списка
        };
    },
    mounted() {

        const role = localStorage.getItem('role');

		if (role === 'user')
			this.$router.push('/cases');

        // Загрузка данных о пользователях
        this.$axios.get('/users')
            .then(response => {
                // Обработка успешного ответа
                this.investigationInfo = response.data.users;
            })
            .catch(error => {
                // Обработка ошибок
                console.error('Произошла ошибка при загрузке данных о пользователях:', error);
            });
        
        this.$axios.get('/cases', {
            params: {
                user_id: localStorage.getItem('id')
            }
        })
        .then(response => {
            console.log(response.data.investigations)
          // Обработка успешного ответа
          this.investigations = response.data.investigations;
        })
        .catch(error => {
          // Обработка ошибок
          console.error('Произошла ошибка:', error);
        });
    },

    computed: {
        filteredInvestigations() {
            return this.investigations.filter(investigation => investigation.investigation_info[0].id != localStorage.getItem('id'));
        }
    },

    methods: {
        handleFileChange(event) {
            const files = event.target.files;
            if (files.length > 0) {
                this.formData.photo_video = files;
            }
        },

        addEvid() {
            const formData = new FormData();

            // Добавляем каждый файл к FormData
            for (let i = 0; i < this.formData.photo_video.length; i++) {
                formData.append('photo_video[]', this.formData.photo_video[i]);
            }

            // Добавляем остальные поля к объекту formData
            formData.append('name', this.formData.name);
            formData.append('investigation_id', this.formData.investigation_id);
            formData.append('address', this.formData.address);
            formData.append('location', this.formData.location);
            formData.append('info', this.formData.info);
            formData.append('specialist_fio', this.formData.specialist_fio);
            formData.append('investigator_fio', this.formData.investigator_fio);
            formData.append('evid_info', this.formData.evid_info);
            formData.append('user_id', localStorage.getItem('id'));
            formData.append('status', this.formData.status);

            // Отправляем formData на сервер
            this.$axios.post('/evid/add', formData)
                .then(response => {
                    // Обработка успешного ответа
                    window.location.reload();
                    console.log('Данные успешно отправлены:', response.data);
                })
                .catch(error => {
                    // Обработка ошибок
                    console.error('Произошла ошибка при отправке данных:', error);
                });
        }
        
        // addEvid() {
        //     // Отправка данных о новом деле на сервер     
        //     this.$axios.post('/case/add', this.formData)
        //         .then(response => {
        //             // Обработка успешного ответа
        //             console.log('Вещ док успешно добавлено:', response.data);
        //             // Очистка формы после успешного добавления дела
        //             this.formData.name = '';
        //             this.formData.user_id = '';

        //             this.showAlertSuccess = true;

        //             setTimeout(() => {
        //                 // Выполняем редирект на другую страницу
        //                 this.$router.push('/cases');
        //             }, 2000);
        //         })
        //         .catch(error => {
        //             // Обработка ошибок
        //             console.error('Произошла ошибка при добавлении вещ. дока:', error);
        //             this.showAlertError = true;
        //         });
        // }
    }
};
</script>


<style scoped>
    .form-control::placeholder {
        color: #000;
        font-weight: 400;
    }

    .form-control {
        font-weight: bold;
    }
</style>