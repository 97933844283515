<template>
    <div>
      <h2>Register</h2>
      <form @submit.prevent="register">
        <label for="email">E-mail:</label>
        <input v-model="email" type="email" id="email" required>
  
        <label for="password">Password:</label>
        <input v-model="password" type="password" id="password" required>
  
        <button type="submit">Register</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        email: '',
        password: ''
      };
    },
    methods: {
      register() {

        const email = this.email.split('@')[0];

        const axiosRegister = {
            name: email,
            email: this.email,
            password: this.password,
            password_confirmation: this.password,
            role: 'user',
        }

        // Выполнение запроса на сервер для регистрации
        this.$axios.post('/register', axiosRegister)
          .then(response => {
            console.log('Registration successful', response.data);
            // Дополнительная логика после успешной регистрации
          })
          .catch(error => {
            console.error('Registration failed: ', error);
            // Обработка ошибки регистрации
          });
      }
    }
  };
  </script>
  