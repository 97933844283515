
import { createRouter, createWebHistory } from 'vue-router';

import Login from './components/auth/AuthLogin.vue';
import Register from './components/auth/AuthRegister.vue';

import CaseList from './components/case/CaseList.vue';
import CaseAdd from './components/case/CaseAdd.vue';
import CaseDetail from './components/case/CaseDetail.vue';

import EvidAdd from './components/evid/EvidAdd.vue';
import EvidDetail from './components/evid/EvidDetail.vue';
import EvidList from './components/evid/EvidList.vue';
// import Header from './components/template/HeaderTemplate.vue';

const routes = [
  { path: '/login', component: Login },
  { path: '/register', component: Register },

  { path: '/cases', component: CaseList },
  { path: '/case/add', component: CaseAdd },
  { path: '/case/:id', component: CaseDetail, props: true },


  { path: '/evid/:id', component: EvidDetail, props: true },
  { path: '/evid/add', component: EvidAdd, props: true },
  { path: '/evids', component: EvidList },
  // Добавьте другие маршруты при необходимости
];


const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  
  const token = getTokenFromCookie();

  // const token = this.$cookies.get('token');

  if (!token && to.path !== '/login') {
    next('/login');
  } else {
    next();
  }
});

function getTokenFromCookie() {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=');
    if (cookie[0] === 'token') {
      return cookie[1];
    }
  }
  return null;
}

export default router;



