<template>
  <div class="card">
    <div class="card-header">
      Дело #{{ caseData.id }}
    </div>
    <div class="card-body">
      <p>Название: {{ caseData.name }}</p>
      <p>Статус: {{ caseData.status }}</p>
      <p>Дата создания: {{ new Date(caseData.created_at).toLocaleString() }}</p>
      <p>Дата обновления: {{ new Date(caseData.updated_at).toLocaleString() }}</p>

      <div v-if="checkGive()" class="accordion" id="accordionExample">
        <div class="accordion-item border-danger">
          <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Передать дело
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="form-floating my-4">
                <select id="userId" v-model="formData.user_id" class="form-select" required>
                  <option v-for="user in filteredInvestigationInfo" :key="user.id" :value="user.id">
                    {{ user.fio }} ({{ user.role }})
                  </option>
                </select>

                <label for="userId">Пользователь:</label>
                <br>
                <button @click="giveCase" class="btn btn-danger">Передать дело</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br>
      <h3>Информация о том, кто ведет дело:</h3>

      <div class="accordion" id="accordionUsers" v-for="(info, index) in caseData.investigation_info" :key="index">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + index" aria-expanded="true" aria-controls="collapseOne">
              <div class="row align-items-center">
                <div class="col pe-16">
                  <p class="d-flex align-items-center hp-p1-body mb-0">
                    <span>Пользователь: {{ userInfo(info.user_id) }}</span>
                  </p>
                </div>
              </div>
            </button>
          </h2>

          <div :id="'collapse' + index" class="accordion-collapse collapse" aria-labelledby="headingOne"
            data-bs-parent="#accordionUsers">
            <div class="accordion-body">
              <!-- <p class="hp-p1-body"> -->
              <div>Дата создания: {{ new Date(info.created_at).toLocaleString() }}</div>
              <!-- </p> -->
            </div>
          </div>
        </div>
      </div>
    
      <br>
      <br>
      <h3> Информация о вещ доках:</h3>

      <div v-if="evids.length > 0" class="accordion" id="accordionEvidInfo">
        <div class="accordion-item" v-for="(info, index) in evids" :key="index">
          <h2 class="accordion-header" :id="'headingEvidInfo' + index">
            <button class="accordion-button" type="button" data-bs-toggle="collapse"
              :data-bs-target="'#collapseEvidInfo' + index" aria-expanded="true"
              :aria-controls="'collapseEvidInfo' + index">
              Название вещ. дока: {{info.name }}
            </button>
          </h2>
          <div :id="'collapseEvidInfo' + index" class="accordion-collapse collapse"
            :aria-labelledby="'headingEvidInfo' + index" data-bs-parent="#accordionEvidInfo">
            <div class="accordion-body">
              <ul class="list-group">
                <li class="list-group-item">Локация: {{ info.location }}</li>
                <li class="list-group-item">Адрес: {{ info.address }}</li>
                <li class="list-group-item">ФИО специалиста: {{ info.specialist_fio }}</li>
                <li class="list-group-item">ФИО создателя: {{ info.investigator_fio }}</li>
                <li class="list-group-item">Дата создания: {{ new Date(info.created_at).toLocaleString() }}</li>
                <!-- <li class="list-group-item"><img :src="decodeBase64(info.qr, 'image/svg+xml')" class="d-block d-sm-none"></li> -->
                <li class="list-group-item"><img :src="decodeBase64(info.qr, 'image/svg+xml')" class="qr-img"></li>
              </ul>
              <br>
              <a :href="'/evid/' + info.id" class="btn btn-primary">Перейти к вещ доку</a>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        Вещ доков нет.
      </div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      caseData: {},
      formData: {
        user_id: '',
        investigation_id: '',
      },
      caseId: 0,
      investigation_user_id: 0,
      showAlertError: false,
      showAlertSuccess: false,
      investigationInfo: [], // Данные о пользователях для выпадающего списка
      evids: [],
    };
  },
  mounted() {
    // Получаем id дела из URL
    this.caseId = this.$route.params.id;

    // Загрузка данных о конкретном деле
    this.$axios.get(`/case/${this.caseId}`)
      .then(response => {
        // Обработка успешного ответа
        this.caseData = response.data.investigation;

        console.log(this.caseData)

        this.investigation_user_id = this.caseData.investigation_info[this.caseData.investigation_info.length - 1].user_id;
      })
      .catch(error => {
        // Обработка ошибок
        console.error('Произошла ошибка при загрузке данных о деле:', error);
      });

    // Загрузка данных о пользователях
    this.$axios.get('/users')
      .then(response => {
        // Обработка успешного ответа
        this.investigationInfo = response.data.users;
      })
      .catch(error => {
        // Обработка ошибок
        console.error('Произошла ошибка при загрузке данных о пользователях:', error);
      });

    // Загрузка данных о вещ доках
    this.$axios.post(`/evids/${this.caseId}`)
      .then(response => {
        // Обработка успешного ответа
        this.evids = response.data.evids;

        console.log(this.evids)
      })
      .catch(error => {
        // Обработка ошибок
        console.error('Произошла ошибка при загрузке вещ доков:', error);
      });
  },

  computed: {
    filteredInvestigationInfo() {
      return this.investigationInfo.filter(user => user.id != localStorage.getItem('id'));
    },
  },

  methods: {
    decodeBase64(base64String, mimeType = 'image/svg+xml') {
      if (base64String === null) {
        return null;
      }

      const blob = this.base64ToFile(base64String.replace('data:image/png;base64,', ''), mimeType);
      const url = URL.createObjectURL(blob);
      
      return url;
    },

    base64ToFile(base64String, mimeType) {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });
      return blob;
    },

    userInfo(user_id) {
      const user = this.investigationInfo.find(user => user.id === user_id);
      if (user) {
        // Пользователь найден
        const fio = user.fio;
        const role = user.role;

        return `${fio} (${role})`;
      } else {
        return null;
      }
      // return `${info[0].fio} (${info[0].role})`;
    },
    giveCase() {
      this.formData.investigation_id = this.caseId;

      // Отправка данных о новом деле на сервер     
      this.$axios.post('/case/investigation-add', this.formData)
        .then(response => {
          // Обработка успешного ответа
          console.log('Дело успешно передано:', response.data);
          // Очистка формы после успешного добавления дела
          this.formData.investigation_id = '';
          this.formData.user_id = '';

          this.showAlertSuccess = true;

          setTimeout(() => {
            // Выполняем редирект на другую страницу
            this.$router.push('/cases');
          }, 1000);
        })
        .catch(error => {
          // Обработка ошибок
          console.error('Произошла ошибка при добавлении дела:', error);
          this.showAlertError = true;
        });
    },

    checkGive() {
      return this.investigation_user_id == localStorage.getItem('id')
    },

    checkOptionUser(user_id) {
      return user_id == localStorage.getItem('id')
    },
  }
};
</script>

<style scoped>
  .accordion-item {
    margin: 15px 0;
  }

  .border-danger {
    border: 1px solid red;
    border-radius: 8px;
  }

  .qr-img {
    width: 300px;
  }

  @media screen and (max-width: 500px) {
    .qr-img {
      width: 100%;
    }
  }
</style>
