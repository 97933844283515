import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios';
import VueCookies from 'vue-cookies';

const app = createApp(App);

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
axios.defaults.baseURL = 'https://api.qr.light-prog.ru/api';

app.use(router);
app.config.globalProperties.$axios = axios;

// app.use(VueCookies);

// app.$cookies.config('1d')

app.use(VueCookies, {
    expireTimes: "1d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
});

app.mount('#app')
