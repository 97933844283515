<template>
    <div class="container">
        <div v-if="loading">Загрузка...</div>
        <div v-else>
            <ul class="nav nav-pills mb-12" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                        aria-selected="true">Активные вещ доки</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                        aria-selected="false">Созданные вещ доки</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                        aria-selected="false">Получить вещ доки</button>
                </li>
            </ul>

            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div class="row">
                        <div v-for="item in filteredEvidActive" :key="item.id" class="card col-md-4 col-lg-4 col-12">
                            <div class="card-header">
                                ID #{{ item.id }}
                            </div>

                            <div class="card-body">
                                <h5 class="card-title">{{ item.name }}</h5>
                                <h6 class="card-subtitle mb-2 text-muted">Статус: {{ item.status }}</h6>
                                <br>
                                <a :href="'/evid/' + item.id" class="btn btn-gradient btn-primary"><span>Перейти к вещ доку</span></a>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div v-for="item in filteredEvidCreate" :key="item.id" class="card">
                        <div class="card-header">
                            ID #{{ item.id }}
                        </div>

                        <div class="card-body">
                            <h5 class="card-title">{{ item.name }}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">Статус: {{ item.status }}</h6>
                            <br>
                            <a :href="'/evid/' + item.id" class="btn btn-gradient btn-primary"><span>Перейти к вещ доку</span></a>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact">
                    <div v-for="item in filteredCaseGet" :key="item.id" class="card">
                        <div class="card-header">
                            ID #{{ item.id }}
                        </div>

                        <div class="card-body">
                            <h5 class="card-title">{{ item.name }}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">Статус: {{ item.status }}</h6>
                            <br>
                            <a :href="'/evid/' + item.id" class="btn btn-gradient btn-danger"><span>Получить вещ док</span></a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: {},
            evids: {},
            loading: true
        };
    },
    mounted() {
        this.$axios.get('/evids')
            .then(response => {
                this.evids = response.data.evids;

                this.loading = false;
            })
    },

    computed: {
        filteredEvidActive() {
            return this.evids.map(item => {
                const lastEvidInfo = item.evid_info[item.evid_info.length - 1];
                if (lastEvidInfo && lastEvidInfo.status != 0 && lastEvidInfo.user_id == localStorage.getItem('id')) {
                    return item;
                }
            }).filter(Boolean);
        },

        filteredEvidCreate() {
            return this.evids.map(item => {
                const firstEvidInfo = item.evid_info[0];
                if (firstEvidInfo && firstEvidInfo.status != 0 && firstEvidInfo.user_id == localStorage.getItem('id')) {
                    return item;
                }
            }).filter(Boolean);
        },

        filteredCaseGet() {
            return this.evids.flatMap(item => {
                const lastEvidInfo = item.evid_info[item.evid_info.length - 1];
                if (lastEvidInfo && lastEvidInfo.status == 0 && lastEvidInfo.user_id == localStorage.getItem('id')) {
                    return item;
                }
            }).filter(Boolean);
        },

        isRole() {
            const role = localStorage.getItem('role');

            if (role === 'admin' || role === 'control')
                return true;
            return false;
        },
    },
};
</script>

<style scoped>
.card {
    margin: 30px 0;
}
</style>