<template>
  <!-- <main class="form-signin text-center">
    <form @submit.prevent="login">
      <h1 class="h3 mb-3 fw-normal">Вход на платформу</h1>

      <div v-if="showAlertError" class="alert alert-danger" role="alert">Ошибка. Что-то пошло не так.</div>
      <div v-if="showAlertSuccess" class="alert alert-success" role="alert">Авторизация прошла успешно. Вы будете перенаправлены через 3 секунды.</div>

      <div class="form-floating">
        <input v-model="email" type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
          required>
        <label for="floatingInput">E-mail</label>
      </div>
      <div class="form-floating my-4">
        <input v-model="password" type="password" class="form-control" id="floatingPassword" placeholder="Пароль"
          required>
        <label for="floatingPassword">Пароль</label>
      </div>

      <button class="w-100 btn btn-lg btn-primary" type="submit">Войти</button>
      <p class="mt-5 mb-3 text-muted">© 2024</p>
    </form>
  </main> -->


  <div class="row hp-authentication-page d-flex flex-column">
        <br><br>
        <div class="px-32 flex-shrink-1 col d-flex">
            <div class="row h-100 m-auto w-100 align-items-center" style="max-width: 390px;">
                <div class="col-12">
                    <h1 class="mb-0 mb-sm-24 text-center">Войти</h1>

                    <div v-if="showAlertError" class="alert alert-danger" role="alert">Ошибка. Что-то пошло не так.</div>
                    <div v-if="showAlertSuccess" class="alert alert-success" role="alert">Авторизация прошла успешно. Вы будете перенаправлены через 3 секунды.</div>

                    <form @submit.prevent="login" class="mt-16 mt-sm-32 mb-8">
                        <div class="mb-16">
                            <label for="loginEmail" class="form-label">E-mail :</label>
                            <input v-model="email" type="email" class="form-control" id="loginEmail">
                        </div>

                        <div class="mb-16" >
                            <label for="loginPassword" class="form-label">Пароль :</label>
                            <input v-model="password" type="password" class="form-control" id="loginPassword">
                        </div>

                        <button type="submit" class="btn btn-primary w-100">Войти</button>
                    </form>
                </div>
            </div>
        </div>

        
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        email: '',
        password: '',
        showAlertError: false,
        showAlertSuccess: false,
      };
    },
    methods: {
      login() {
        // Выполнение запроса на сервер для авторизации
        this.$axios.post('/login', { email: this.email, password: this.password })
          .then(response => {
    
            const
              id = response.data.id,
              role = response.data.role,
              token = response.data.token.split('|')[1];

            this.$cookies.set('token', token)

            localStorage.setItem('id', id);
            localStorage.setItem('role', role);

            this.showAlertSuccess = true;

            setTimeout(() => {
              // Выполняем редирект на другую страницу
              this.$router.push('/cases');
            }, 3000);
            
          })
          .catch(error => {
            this.showAlertError = true;
            console.error('Login failed', error);
          });
      }
    }
  };
  </script>
  