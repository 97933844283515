<template>
	<main class="form-signin text-center">
		<form @submit.prevent="login">
			<h1 class="h3 mb-3 fw-normal">Добавить дело в систему</h1>

			<div v-if="showAlertError" class="alert alert-danger" role="alert">Ошибка. Что-то пошло не так.</div>
			<div v-if="showAlertSuccess" class="alert alert-success" role="alert">Дело {{ formData.name }} добавлено. Вы
				будете перенаправлены через 2 секунды.</div>

			<div class="form-floating">
				<input type="text" id="name" v-model="formData.name" class="form-control" placeholder="Название дела"
					required>
				<label for="name">Название дела:</label>
			</div>

			<div class="form-floating my-4">
				<select id="userId" v-model="formData.user_id" class="form-select" required>
					<option v-for="user in investigationInfo" :key="user.id" :value="user.id">{{ user.fio }} ({{
			user.role }})
					</option>
				</select>

				<label for="userId">Пользователь:</label>

			</div>

			<button @click="addCase" class="w-100 btn btn-lg btn-primary" type="submit">Добавить дело</button>
		</form>
	</main>
</template>

<script>

export default {
	data() {
		return {
			formData: {
				name: '',
				user_id: '',
				status: 'open',

			},
			showAlertError: false,
			showAlertSuccess: false,
			investigationInfo: [] // Данные о пользователях для выпадающего списка
		};
	},
	mounted() {

		const role = localStorage.getItem('role');

		if (role === 'user')
			this.$router.push('/cases');

		// Загрузка данных о пользователях
		this.$axios.get('/users')
			.then(response => {
				// Обработка успешного ответа
				this.investigationInfo = response.data.users;
			})
			.catch(error => {
				// Обработка ошибок
				console.error('Произошла ошибка при загрузке данных о пользователях:', error);
			});
	},
	methods: {
		addCase() {
			// Отправка данных о новом деле на сервер     
			this.$axios.post('/case/add', this.formData)
				.then(response => {
					// Обработка успешного ответа
					console.log('Дело успешно добавлено:', response.data);
					// Очистка формы после успешного добавления дела
					this.formData.name = '';
					this.formData.user_id = '';

					this.showAlertSuccess = true;

					setTimeout(() => {
						// Выполняем редирект на другую страницу
						this.$router.push('/cases');
					}, 2000);
				})
				.catch(error => {
					// Обработка ошибок
					console.error('Произошла ошибка при добавлении дела:', error);
					this.showAlertError = true;
				});
		}
	}
};
</script>