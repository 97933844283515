<template>
    <footer>
        <div class="col-12">
            <div class="text-center text-white"> Copyright 2024. </div>

            <!-- <div class="row align-items-center justify-content-center mx-n8">
                <div class="w-auto hp-flex-none px-8 col">
                    <a href="javascript:;" class="hp-text-color-black-80 hp-text-color-dark-40"> Privacy Policy </a>
                </div>

                <div class="w-auto hp-flex-none px-8 col">
                    <a href="javascript:;" class="hp-text-color-black-80 hp-text-color-dark-40"> Term of use </a>
                </div>
            </div> -->
        </div>
    </footer>
</template>

<script>
export default {
    // Определения компонента

    data() {
        return {};
    }
};
</script>


<style scoped>

    footer {
        margin: 50px 0;
        padding: 15px 0;

        border-radius: 15px;
        background: #7393B3;

        font-weight: bold;
    }

</style>