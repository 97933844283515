<template>
    <div>

        <button @click="getEvid" v-if="checkEvid()" class="btn btn-danger w-100">Получить вещ док</button>
        <br>
        <br>
        <h3> Информация о вещ доке:</h3>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-12">
                <ul class="list-group">
                    <li class="list-group-item">Локация: {{ evid.location }}</li>
                    <li class="list-group-item">Адрес: {{ evid.address }}</li>
                    <li class="list-group-item">ФИО специалиста: {{ evid.specialist_fio }}</li>
                    <li class="list-group-item">ФИО создателя: {{ evid.investigator_fio }}</li>
                    <li class="list-group-item">Дата создания: {{ new Date(evid.created_at).toLocaleString() }}</li>
                </ul>
                <br>

            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <img :src="qr" class="w-100">
                <br><br>
                <a :href="qr" class="btn btn-danger w-100" download>Скачать QR код</a>
            </div>
        </div>
        

        <div v-if="checkIssetEvid()">
            <br><br>
            <h3> Действия с вещ доком:</h3>
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Добавить информацию о вещ доке
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="form-floating my-4">
                                <form @submit.prevent="login">

                                    <div class="form-floating my-4">
                                        <textarea rows="10" placeholder="Введите информацию"  class="w-100" id="evid_info" v-model="formData.evid_info" required></textarea>
                                    </div>

                                    <div class="form-floating my-4">
                                        <input type="file" class="form-control" id="photo_video" multiple
                                            @change="handleFileChangeEvidInfo">
                                    </div>


                                    <button @click="addEvidInfo" type="submit"
                                        class="btn btn-primary">Отправить</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion" id="accordionExample2">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree2">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree2" aria-expanded="false" aria-controls="collapseThree2">
                            Передать Вещ док
                        </button>
                    </h2>
                    <div id="collapseThree2" class="accordion-collapse collapse" aria-labelledby="headingThree2"
                        data-bs-parent="#accordionExample2">
                        <div class="accordion-body">
                            <div class="form-floating my-4">
                                <form @submit.prevent="login">

                                    <div class="form-floating my-4">
                                        <select id="SelectUserId" v-model="select_user_id" class="form-select" required>
                                            <option v-for="user in filteredInvestigationInfo" :key="user.id"
                                                :value="user.id">{{ user.fio }} ({{ user.role }})
                                            </option>
                                        </select>

                                        <label for="SelectUserId">Пользователь:</label>
                                    </div>

                                    <button @click="addEvidInfoUser" type="submit"
                                        class="btn btn-danger">Передать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <br>
        <div v-if="evids.length > 0">
            <h3>История вещ дока:</h3>
            <div class="accordion" id="accordionEvidInfo">
                <div class="accordion-item" v-for="(info, index) in evids" :key="index">
                    <h2 class="accordion-header" :id="'headingInfoEvid' + index">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            :data-bs-target="'#collapseEvidInfo' + index" aria-expanded="true"
                            :aria-controls="'collapseEvidInfo' + index">

                            {{ userInfo(info.user_id) }} ({{ new Date(info.created_at).toLocaleString() }})
                            <span class="badge bg-primary-4 hp-bg-dark-primary border-primary text-primary ms-16">{{ setStatus(info.status) }}</span>
                        </button>
                    </h2>
                    <div :id="'collapseEvidInfo' + index" class="accordion-collapse collapse"
                        :aria-labelledby="'headingEvidInfo' + index" data-bs-parent="#accordionEvidInfo">
                        <div class="accordion-body">
                            <p><pre>{{ info.info }}</pre></p>

                            <div class="images">
                                <div v-for="(element, index) in showImgVideo(info.photo_video)" :key="index" class="row">
                                    <div v-html="element" class="col-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    data() {
        return {
            select_user_id: 0,
            evidId: 0,
            showAlertError: false,
            showAlertSuccess: false,
            investigationInfo: [], // Данные о пользователях для выпадающего списка
            evid: 0,
            evids: [],


            formData: {
                investigation_id: 0,
                name: '',
                address: '',
                location: '',
                info: '',
                specialist_fio: '',
                investigator_fio: '',
                evid_info: '',
                photo_video: '',
                user_id: 0,
                status: 1,

            },
        };
    },
    mounted() {
        // Получаем id дела из URL
        this.evidId = this.$route.params.id;

        // Загрузка данных о пользователях
        this.$axios.get('/users')
            .then(response => {
                // Обработка успешного ответа
                this.investigationInfo = response.data.users;
            })
            .catch(error => {
                // Обработка ошибок
                console.error('Произошла ошибка при загрузке данных о пользователях:', error);
            });

        // Загрузка данных о вещ доках
        this.$axios.post(`/evid/${this.evidId}`)
            .then(response => {

                // Обработка успешного ответа
                this.evid = response.data.evid;
                this.evids = response.data.evid_info;

                this.qr = this.handleFileChange(this.evid.qr.replace('data:image/png;base64,', ''), 'image/svg+xml');

            })
            .catch(error => {
                // Обработка ошибок
                console.error('Произошла ошибка при загрузке вещ доков:', error);
            });
    },

    computed: {
        filteredInvestigationInfo() {
            return this.investigationInfo.filter(user => user.id != localStorage.getItem('id'));
        }
    },

    methods: {
        checkEvid() {
            if (this.evids.length > 0) {
                if (
                    (this.evids[this.evids.length - 1].user_id == localStorage.getItem('id')) &&
                    (this.evids[this.evids.length - 1].status == 0)
                )
                    return true;
            }

        },

        handleFileChange(base64String, mimeType) {
            const blob = this.base64ToFile(base64String, mimeType);
            const url = URL.createObjectURL(blob);

            return url;
        },

        handleFileChangeEvidInfo(event) {
            const files = event.target.files;
            if (files.length > 0) {
                this.formData.photo_video = files;
            }
        },

        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },

        base64ToFile(base64String, mimeType) {
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeType });
            return blob;
        },

        addEvidInfo() {
            this.formData.evid_id = this.evid.id;
            this.formData.status = 1;

            const formData = new FormData();

            // Добавляем каждый файл к FormData
            for (let i = 0; i < this.formData.photo_video.length; i++) {
                formData.append('photo_video[]', this.formData.photo_video[i]);
            }

            formData.append('evid_id', this.formData.evid_id);
            formData.append('user_id', localStorage.getItem('id'));
            formData.append('evid_info', this.formData.evid_info);
            formData.append('status', this.formData.status);

            // Отправляем formData на сервер
            this.$axios.post('/evid/evid-add', formData)
                .then(response => {
                    // Обработка успешного ответа
                    window.location.reload();

                    console.log('Данные успешно отправлены:', response.data);
                })
                .catch(error => {
                    // Обработка ошибок
                    console.error('Произошла ошибка при отправке данных:', error);
                });
        },

        userInfo(user_id) {
            const user = this.investigationInfo.find(user => user.id == user_id);
            if (user) {
                // Пользователь найден
                const fio = user.fio;
                const role = user.role;

                return `${fio} (${role})`;
            } else {
                return null;
            }
            // return `${info[0].fio} (${info[0].role})`;
        },

        addEvidInfoUser() {
            this.formData.evid_id = this.evid.id;
            this.formData.status = 0;

            const formData = new FormData();

            // Добавляем каждый файл к FormData
            for (let i = 0; i < this.formData.photo_video.length; i++) {
                formData.append('photo_video[]', this.formData.photo_video[i]);
            }

            const info = `${this.userInfo(this.evids[this.evids.length - 1].user_id)} передал вещ док ${this.userInfo(this.select_user_id)}`;

            formData.append('evid_id', this.formData.evid_id);
            formData.append('user_id', this.select_user_id);
            formData.append('evid_info', info);
            formData.append('photo_video[]', '');
            formData.append('status', this.formData.status);

            // Отправляем formData на сервер
            this.$axios.post('/evid/evid-add', formData)
                .then(response => {
                    // Обработка успешного ответа
                    window.location.reload();
                    console.log('Данные успешно отправлены:', response.data);
                })
                .catch(error => {
                    // Обработка ошибок
                    console.error('Произошла ошибка при отправке данных:', error);
                });
        },

        getEvid() {
            this.formData.evid_id = this.evid.id;
            this.formData.status = 1;

            const formData = new FormData();

            // Добавляем каждый файл к FormData
            for (let i = 0; i < this.formData.photo_video.length; i++) {
                formData.append('photo_video[]', this.formData.photo_video[i]);
            }

            const info = `Вещ док получил ${this.userInfo(localStorage.getItem('id'))}`;

            formData.append('evid_id', this.formData.evid_id);
            formData.append('user_id', localStorage.getItem('id'));
            formData.append('evid_info', info);
            formData.append('photo_video[]', '');
            formData.append('status', this.formData.status);

            // Отправляем formData на сервер
            this.$axios.post('/evid/evid-add', formData)
                .then(response => {
                    // Обработка успешного ответа
                    window.location.reload();
                    console.log('Данные успешно отправлены:', response.data);
                })
                .catch(error => {
                    // Обработка ошибок
                    console.error('Произошла ошибка при отправке данных:', error);
                });
        },

        checkIssetEvid() {
            if (this.evids.length > 0) {
                if (
                    (this.evids[this.evids.length - 1].user_id == localStorage.getItem('id')) &&
                    (this.evids[this.evids.length - 1].status == 1)
                )
                    return true;
            }
        },

        showImgVideo(photo_video) {
            const img_video = photo_video.split(';');

            const elements = [];

            for (let i = 0; i < img_video.length; i++) {
                // Проверяем, является ли элемент изображением или видео
                if (i % 2 === 0) {
                    if (img_video[i] == '')
                        continue;
                    // console.log(img_video[i + 1])
                    // console.log(img_video[i])
                    // continue;
                    const url = this.handleFileChange(img_video[i + 1].replace('base64,', ''), img_video[i].replace('data:', ''));

                    if (img_video[i].includes('data:image')) {
                        elements.push(`<img src="${url}" class="w-100" alt="Image ${i + 1}">`);
                    } else if (img_video[i].includes('data:video')) {
                        elements.push(`<video class="w-100" controls><source src="${url}" type="video/mp4">Ваш браузер не поддерживает видео</video>`);
                    }
                }
            }

            // Возвращаем массив элементов для отображения в шаблоне Vue
            return elements;
        },

        setStatus(status) {
            if (status == 0) {
                return 'Передал';
            } else {
                return 'Добавил информацию';
            }
        }
    }
};
</script>


<style scoped>
.accordion-item {
    margin: 25px 0;
}
</style>