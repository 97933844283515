<template>
  <div class="container">
    <div v-if="loading">Загрузка...</div>
    <div v-else>
      <ul class="nav nav-pills mb-12" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
            type="button" role="tab" aria-controls="pills-home" aria-selected="true">Активные дела</button>
        </li>
        <li v-if="isRole" class="nav-item" role="presentation">
          <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
            type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Созданные дела</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
            type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Архивные дела</button>
        </li>

        <li v-if="isRole" class="nav-item" role="presentation">
          <button class="nav-link" id="pills-contact-tab2" data-bs-toggle="pill" data-bs-target="#pills-contact2"
            type="button" role="tab" aria-controls="pills-contact2" aria-selected="false">Все дела</button>
        </li>
      </ul>

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div class="row">
            <div v-for="item in filteredCaseActive" :key="item.id" class="card col-md-4 col-lg-4 col-12">
              <div class="card-header">
                ID #{{ item.id }}
              </div>

              <div class="card-body">
                <h5 class="card-title">{{ item.name }}</h5>
                <h6 class="card-subtitle mb-2 text-muted">Статус: {{ item.status }}</h6>
                <br>
                <a :href="'/case/' + item.id" class="btn btn-gradient btn-primary"><span>Перейти к делу</span></a>
              </div>
            </div>
          </div>
        </div>


        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div v-for="item in filteredCaseCreate" :key="item.id" class="card">
            <div class="card-header">
              ID #{{ item.id }}
            </div>

            <div class="card-body">
              <h5 class="card-title">{{ item.name }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">Статус: {{ item.status }}</h6>
              <br>
              <a :href="'/case/' + item.id" class="btn btn-gradient btn-primary"><span>Перейти к делу</span></a>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact">
          <div v-for="item in filteredCaseClosed" :key="item.id" class="card">
            <div class="card-header">
              ID #{{ item.id }}
            </div>

            <div class="card-body">
              <h5 class="card-title">{{ item.name }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">Статус: {{ item.status }}</h6>
              <br>
              <a :href="'/case/' + item.id" class="btn btn-gradient btn-primary"><span>Перейти к делу</span></a>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-contact2" role="tabpanel" aria-labelledby="pills-contact2">
          <div v-for="item in casesAll" :key="item.id" class="card">
            <div class="card-header">
              ID #{{ item.id }}
            </div>

            <div class="card-body">
              <h5 class="card-title">{{ item.name }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">Статус: {{ item.status }}</h6>
              <br>
              <a :href="'/case/' + item.id" class="btn btn-gradient btn-primary"><span>Перейти к делу</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      casesAll: {},
      loading: true
    };
  },
  mounted() {

    this.$axios.get('/cases', {
      params: {
        user_id: localStorage.getItem('id')
      }
    })
      .then(response => {
        // Обработка успешного ответа
        this.data = response.data.investigations;
        this.loading = false;

        console.log(this.data)
      })
      .catch(error => {
        // Обработка ошибок
        console.error('Произошла ошибка:', error);
        this.loading = false;
      });

    this.$axios.get('/cases-all')
      .then(response => {
        // Обработка успешного ответа
        this.casesAll = response.data.investigations;
      })
      .catch(error => {
        // Обработка ошибок
        console.error('Произошла ошибка:', error);
        this.loading = false;
      });
  },

  computed: {
    filteredCaseActive() {
      return this.data.filter((item) => {
        if (item.status != 'closed' && item.investigation_info[item.investigation_info.length - 1].user_id == localStorage.getItem('id')) {
          return item;
        }
      });

    },

    filteredCaseCreate() {
      return this.data.filter((item) => {
        if (item.status != 'closed' && item.investigation_info[0].user_id == localStorage.getItem('id')) {
          return item;
        }
      });
    },

    filteredCaseClosed() {
      return this.data.filter((item) => {
        if (item.status == 'closed') {
          const filteredInfo = item.investigation_info.filter(info => info.user_id == localStorage.getItem('id'));

          if (filteredInfo.length > 0) {
            return item;
          }
        }
      });
    },

    isRole() {
      const role = localStorage.getItem('role');

      if (role === 'admin' || role === 'control')
        return true;
      return false;
    },
  },
};
</script>

<style scoped>
.card {
  margin: 30px 0;
}
</style>